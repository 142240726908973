import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_ZERO } from '../utils/formatbalance';
import { Address, useNetwork, sepolia, mainnet, erc20ABI } from 'wagmi';
import useAccount from './useAccountOverride';
import { getChainClient, getUpdateInterval } from '../utils/zunami';
import { log } from '../utils/logger';
import { getContractNickname } from './useAllowance';
import { base } from '../components/NetworkSelector/NetworkSelector';
import { stZunBtcApsAddress } from '../sushi/lib/constants';

const useBalanceOf = (contractAddress: Address, abi?: any, autoRefresh = false) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const { address: account } = useAccount();
    const [balance, setBalance] = useState(new BigNumber(BIG_ZERO));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                return;
            }

            let result: BigInt = BigInt(0);
            let client = getChainClient(chainId);

            switch (chainId) {
                case mainnet.id:
                    setLoading(true);

                    // @ts-ignore
                    result = await client.readContract({
                        address: contractAddress,
                        abi: abi ? abi : erc20ABI,
                        functionName: 'balanceOf',
                        args: [
                            account, //owner
                        ],
                    });

                    setLoading(false);

                    log(
                        `[${getContractNickname(
                            contractAddress
                        )}]->balanceOf(${account}). Result: ${result.toString()})`
                    );

                    setBalance(new BigNumber(result.toString()));
                    break;
                case sepolia.id:
                    setLoading(true);

                    // @ts-ignore
                    result = await client.readContract({
                        address: contractAddress,
                        abi: abi ? abi : erc20ABI,
                        functionName: 'balanceOf',
                        args: [
                            account, //owner
                        ],
                    });

                    // log(
                    //     `[${getContractNickname(
                    //         contractAddress
                    //     )}]->balanceOf(${account}). Result: ${result.toString()})`
                    // );

                    setLoading(false);

                    setBalance(new BigNumber(result.toString()));

                    break;
                case base.id:
                    setLoading(true);

                    if (contractAddress === stZunBtcApsAddress) {
                        setLoading(false);
                        setBalance(BIG_ZERO);
                        return;
                    }

                    // @ts-ignore
                    result = await client.readContract({
                        address: contractAddress,
                        abi: abi ? abi : erc20ABI,
                        functionName: 'balanceOf',
                        args: [
                            account, //owner
                        ],
                    });

                    log(
                        `[${getContractNickname(
                            contractAddress
                        )}]->balanceOf(${account}). Result: ${result.toString()})`
                    );

                    setLoading(false);

                    setBalance(new BigNumber(result.toString()));

                    break;
            }
        };

        if (account) {
            fetchBalance();
        }

        let refreshInterval = setInterval(fetchBalance, getUpdateInterval());
        return () => clearInterval(refreshInterval);
    }, [account, chainId, contractAddress, abi]);

    // log(
    //     `[SMART] Balance of (${getContractNickname(
    //         contractAddress
    //     )}) - ${balance.toString()} - loading (${loading})`
    // );

    return balance;
};

export default useBalanceOf;
