import React, { useState, useEffect, useMemo, useRef } from 'react';
import './ZunStables.scss';
import { MobileSidebar } from '../components/SideBar/MobileSidebar/MobileSidebar';
import { AllServicesPanel } from '../components/AllServicesPanel/AllServicesPanel';
import { SideBar } from '../components/SideBar/SideBar';
import { Header } from '../components/Header/Header';
import { SidebarTopButtons } from '../components/SidebarTopButtons/SidebarTopButtons';
import { Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApyChart } from '../components/ApyChart/ApyChart';
import { MicroCard } from '../components/MicroCard/MicroCard';
import { AddressButtons } from '../components/AddressButtons/AddressButtons';
import { BIG_TEN, BIG_ZERO, bigNumberToNumber, getFullDisplayBalance, NULL_ADDRESS } from '../utils/formatbalance';
import { StrategyListItem } from '../components/StrategyListItem/StrategyListItem';
import { PieChart2 } from '../components/PieChart/PieChart';
import { renderMobileMenu } from '../components/Header/NavMenu/NavMenu';
import { ZunPoolSummary } from '../components/ZunPoolSummary/ZunPoolSummary';
import { getChainById, getChainClient, getZunBtcAddress, getZunEthAddress, getZunUsdAddress } from '../utils/zunami';
import { useNetwork } from 'wagmi';
import {
    getZunBtcStratsUrl,
    getZunEthHistoricalApyUrl,
    getZunEthStratsUrl,
    getZunUsdHistoricalApyUrl,
    getZunBtcHistoricalApyUrl,
    getZunUsdStratsUrl,
    uzdStakingInfoUrl,
} from '../api/api';
import useTotalSupply from '../hooks/useTotalSupply';
import { fallbackData } from './Main';
import useFetch from 'react-fetch-hook';
import { poolDataToChartData } from '../functions/pools';
import useBalanceOf from '../hooks/useBalanceOf';
import { format } from 'date-fns';
import { ZunAggInfo } from './Main.types';
import { base } from '../components/NetworkSelector/NetworkSelector';
import arbAllAbi from '../actions/abi/arbitrum/all.json';
import { createPublicClient, http } from 'viem';
import { alchemyBaseTransportUrl } from '../config';
import BigNumber from 'bignumber.js';
import { Preloader } from '../components/Preloader/Preloader';
import aeroSugarAbi from '../actions/abi/arbitrum/aerodrome-sugar.json';
import { CoinSwapPanel } from '../components/CoinSwapPanel/CoinSwapPanel';
import { contractAddresses } from '../sushi/lib/constants';

interface CurvePool {
    address: string;
    coins: Array<any>;
}

export interface CurveFactoryDataResponse {
    data: {
        poolData: Array<CurvePool>;
    };
}

export interface FraxDataResponse {
    data: {
        ETH: {
            ohlc: {
                c: number;
            }
        },
        BTC: {
            ohlc: {
                c: number;
            }
        }
    }
}

interface coinInfo {
    zunUSD: string,
    ZETH: string,
    ZUNBTC: string;
}

interface CurveLink {
    url: string;
    title: string;
}

function getStratsUrlByStakingMode(stakingMode: string) {
    let result = getZunUsdStratsUrl();

    switch (stakingMode) {
        case 'ZETH': result = getZunEthStratsUrl(); break;
        case 'ZUNBTC': result = getZunBtcStratsUrl(); break;
    }

    return result;
}

function getSelectedCoinAddress(chainId: number, stakingMode: string) {
    let result = getZunUsdAddress(chainId);

    switch (stakingMode) {
        case 'ZETH': result = getZunEthAddress(chainId); break;
        case 'ZUNBTC': result = getZunBtcAddress(chainId); break;
    }

    return result;
}


export const ZunStables = (): JSX.Element => {
    const { chain } = useNetwork();
    const chainId: number = chain ? chain.id : 1;
    const [tvl, setTvl] = useState('0');
    const [histApyPeriod, setHistApyPeriod] = useState('week');
    const [histApyData, setHistApyData] = useState([]);
    const [stakingMode, setStakingMode] = useState<string>('zunUSD');
    const zunCoinName = useMemo(() => {
        let result = 'zunUSD';

        switch (stakingMode) {
            case 'ZETH': result = 'zunETH'; break;
            case 'ZUNBTC': result = 'zunBTC'; break;
        }

        return result;
    }, [stakingMode]);
    const contractAddress = useMemo(() => {
        let result = getZunUsdAddress(chainId);

        switch (stakingMode) {
            case 'ZETH': result = getZunEthAddress(chainId); break;
            case 'ZUNBTC': result = getZunBtcAddress(chainId); break;
        }

        return result;
    }, [stakingMode, chainId]);

    const totalSupply = useTotalSupply(contractAddress);

    // APY chart data
    useEffect(() => {
        let url = getZunUsdHistoricalApyUrl(histApyPeriod);

        switch (stakingMode) {
            case 'ZETH': url = getZunEthHistoricalApyUrl(histApyPeriod); break;
            case 'ZUNBTC': url = getZunBtcHistoricalApyUrl(histApyPeriod); break;
        }

        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((items) => {
                setHistApyData(items.data);
            })
            .catch((error) => {
                setHistApyData([]);
            });
    }, [histApyPeriod, stakingMode]);

    const [uzdStatLoading, setUzdStatLoading] = useState(true);
    const [uzdStatData, setUzdStatData] = useState<ZunAggInfo>(fallbackData);

    // Load aggregated info
    useEffect(() => {
        fetch(uzdStakingInfoUrl)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setUzdStatLoading(false);

                // if some item is NULL, then use fallback data
                Object.keys(data.info).forEach((index) => {
                    if (!data.info[index]) {
                        data.info[index] = {
                            monthlyAvgApr: 0,
                            threeMonthAvgApr: 0,
                            apr: 0,
                            apy: 0,
                            tvl: BIG_ZERO,
                            tvlUsd: 0,
                        };
                    }
                });

                setUzdStatData(data);
            })
            .catch((error) => {
                setUzdStatData(fallbackData);
                setUzdStatLoading(false);
            });
    }, []);

    // TVL
    useEffect(() => {
        if (!uzdStatData) {
            return;
        }

        setTvl(uzdStatData.totalTvlUsd.toString());
    }, [uzdStatData]);

    // APY 30,90 days popover
    const apyPopover = useMemo(() => {
        let apy30 = 0;
        let apy90 = 0;

        if (uzdStatData) {
            switch (stakingMode) {
                case 'zunUSD':
                    apy30 = uzdStatData.info.zunUSD.monthlyAvgApr;
                    apy90 = uzdStatData.info.zunUSD.threeMonthAvgApr;
                    break;
                case 'ZETH':
                    apy30 = uzdStatData.info.zunETH.monthlyAvgApr;
                    apy90 = uzdStatData.info.zunETH.threeMonthAvgApr;
                break;
                case 'ZUNBTC':
                    apy30 = uzdStatData.info.zunETH.monthlyAvgApr;
                    apy90 = uzdStatData.info.zunETH.threeMonthAvgApr;
                break;
            }
        }

        return (
            <Popover>
                <Popover.Body>
                    <div className="">
                        <span>Average APR in 30 days: </span>
                        <span className="text-primary">{apy30.toFixed(2)}%</span>
                    </div>
                    <div className="">
                        <span>Average APR in 90 days: </span>
                        <span className="text-primary">{apy90.toFixed(2)}%</span>
                    </div>
                </Popover.Body>
            </Popover>
        );
    }, [stakingMode, uzdStatData]);

    // Pool strategies
    const { data: rawPoolList } = useFetch(getStratsUrlByStakingMode(stakingMode));

    const poolList = useMemo(() => {
        let tvl = uzdStatData.info.zunUSD.tvl;
        let result = [];
        
        switch (stakingMode) {
            case 'ZETH': tvl = uzdStatData.info.zunETH.tvl; break;
            case 'ZUNBTC': tvl = uzdStatData.info.zunBTC.tvl; break;
        }

        if (chainId === base.id) {
            tvl = uzdStatData.info.zunUSDBaseAps.tvl;
        }

        // @ts-ignore
        if (rawPoolList && rawPoolList.strategies.length) {
            result = poolDataToChartData(
                // @ts-ignore
                rawPoolList.strategies,
                tvl
            );

            result = result.map((pool: any) => {
                let unit = '';

                switch (stakingMode) {
                    case 'zunUSD':
                        unit = `$${Math.trunc(pool.tvlUsd).toLocaleString('en', { maximumFractionDigits: 0 })}`;
                    break;
                    case 'ZETH':
                        unit = `${getFullDisplayBalance(pool.tvl)} ETH`;
                        break;
                    case 'ZUNBTC':
                        unit = `${getFullDisplayBalance(pool.tvl)} BTC`;
                    break;
                }

                return {
                    ...pool,
                    amount: unit
                }
            });
        } else {
            result = [];
        }

        return result;
    }, [rawPoolList, stakingMode, uzdStatData, chainId]);

    const zunUsdBalance = useBalanceOf(getZunUsdAddress(chainId));

    // zunUSD price
    const { data: curveData, isLoading: curveDataLoading } = useFetch<CurveFactoryDataResponse>(
        'https://api.curve.fi/api/getPools/ethereum/factory-stable-ng'
    );
    // zunETH price
    // const { data: fraxData, isLoading: fraxDataLoading } = useFetch<FraxDataResponse>(
    //     'https://production.api.coindesk.com/v2/tb/price/ticker?assets=ETH,BTC'
    // );

    const zunCoinPool = useMemo(() => {
        let result: coinInfo = {
            zunUSD: '0',
            ZETH: '0',
            ZUNBTC: '0',
        };

        if (curveDataLoading || !curveData) {
            return result;
        }

        try {
            // @ts-ignore
            const usdPrice = curveData.data.poolData.filter(
                        (pool: any) =>
                            pool.address === '0x8C24b3213FD851db80245FCCc42c40B94Ac9a745'
                    )[0].coins[1].usdPrice;

            const ethPrice = curveData.data.poolData.filter(
                        (pool: any) =>
                            pool.address === '0x3c8159c341EdC996bAdC5146c65AAC6E2A9207Cf'
                    )[0].coins[1].usdPrice;

            const btcPrice = curveData.data.poolData.filter(
                        (pool: any) =>
                            pool.address === '0xFD94A5dCB0E52fDD076Bee3eA1Aa16C48081660C'
                    )[0].coins[0].usdPrice;

            result.zunUSD = `$${String(usdPrice).substring(0,String(usdPrice).indexOf('.') + 5)}`

            // zunETH
            const zunETHpool = curveData.data.poolData.filter(
                          (pool: any) =>
                              pool.address === '0x3A65cbaebBFecbeA5D0CB523ab56fDbda7fF9aAA'
                      )[0].coins[0].usdPrice;

                      
            result.ZETH = String(zunETHpool / ethPrice);
            result.ZETH = `${Number(result.ZETH).toLocaleString('en')} ETH`;

            // zunBTC
            let zunBTCpool = curveData.data.poolData.filter(
                          (pool: any) =>
                              pool.address === '0x6fBc5Ddc181240Cb1d9bcEc6Fdea429036818035'
                      )[0].coins[0].usdPrice;

            if (!zunBTCpool) {
                zunBTCpool = btcPrice;
            }
                
            result.ZUNBTC = String(zunBTCpool / btcPrice);
            result.ZUNBTC = `${Number(result.ZUNBTC).toLocaleString('en')} BTC`;
        } catch (e) {
            debugger;
        }

        return result;
    }, [curveDataLoading, curveData]);

    const collateralValue = useMemo(() => {
        let result = '';

        switch (stakingMode) {
            case 'zunUSD':
                result = uzdStatLoading
                    ? 'loading'
                    : Number(
                            Math.round(
                                uzdStatData.info.zunUSD
                                        .tvlUsd
                            )
                        ).toLocaleString('en', {
                            maximumFractionDigits: 0,
                        });
                break;
            case 'ZETH': result = getFullDisplayBalance(uzdStatData.info.zunETH.tvl, 18, 6); break;
            case 'ZUNBTC': result = getFullDisplayBalance(uzdStatData.info.zunBTC.tvl, 18, 6); break;
        }

        return result;
    }, [stakingMode, uzdStatData.info, uzdStatLoading]);

    const swapHintTarget = useRef(null);
    const [showSwapHint, setShowSwapHint] = useState(false);

    const [allPools, setAllPools] = useState([]);
    const [chainFilter, setChainFilter] = useState('');
    const [protoFilter, setProtoFilter] = useState('');

    useEffect(() => {
        async function getPoolsData() {
            let result: Array<any> = [];
            const zunUsdEthPools = ['factory-stable-ng-104', 'factory-stable-ng-179'];
            const zunUsdArbPools = ['factory-stable-ng-41'];
            const zunEthArbPools = ['factory-stable-ng-50'];
            const zunEthMainnetPools = ['factory-stable-ng-121', 'factory-stable-ng-211'];
            const zunBtcMainnetPools = ['factory-stable-ng-275'];

            // eth pools
            let response = await fetch('https://api.curve.fi/api/getPools/ethereum/factory-stable-ng');
            response = await response.json();

            // @ts-ignore
            response.data.poolData.forEach((pool: any) => {
                const isZunUsdPool = zunUsdEthPools.indexOf(pool.id) !== -1;
                const isZunEthPool = zunEthMainnetPools.indexOf(pool.id) !== -1;
                const isZunBtcPool = zunBtcMainnetPools.indexOf(pool.id) !== -1;
                let mode = isZunUsdPool ? 'zunUSD' : '';

                if (isZunEthPool) {
                    mode = 'zunETH';
                }

                if (isZunBtcPool) {
                    mode = 'zunBTC';
                }

                if (
                    zunUsdEthPools.indexOf(pool.id) !== -1
                    || zunEthMainnetPools.indexOf(pool.id) !== -1
                    || zunBtcMainnetPools.indexOf(pool.id) !== -1
                ) {
                    let firstIcon = `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${pool.coins[0].address.toLowerCase()}.png`;
                    const secondIcon = `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${pool.coins[1].address.toLowerCase()}.png`;
                    let apy = pool.gaugeCrvApy[0];
                    let projApy = pool.gaugeCrvApy[1];

                    // it's zunBTC pool
                    if (pool.coins[0].address.toLowerCase() === contractAddresses.zunBTC[1].toLowerCase()) {
                        firstIcon = '/zunbtc.svg';
                        // apy = apy / 1000;
                        // projApy = projApy / 1000;
                    }

                    result.push({
                        url: `https://curve.fi/#/ethereum/pools/${pool.id}/deposit`,
                        address: pool.address,
                        id: pool.id,
                        icons: [
                            firstIcon,
                            secondIcon,
                        ],
                        coins: `${pool.coins[0].symbol}/${pool.coins[1].symbol}`,
                        protocol: 'curve',
                        chain: 'ethereum',
                        mode: mode,
                        tvlUsd: pool.usdTotal,
                        apy,
                        projApy,
                        reserves: pool.coins,
                    });
                }
            });

            response = await fetch('https://api.curve.fi/api/getPools/arbitrum/factory-stable-ng');
            response = await response.json();

            // @ts-ignore
            response.data.poolData.forEach((pool: any) => {
                const isZunUsdPool = zunUsdArbPools.indexOf(pool.id) !== -1;
                const isZunEthPool = zunEthArbPools.indexOf(pool.id) !== -1;
                let mode = isZunUsdPool ? 'zunUSD' : '';

                if (isZunEthPool) {
                    mode = 'zunETH';
                }

                if (isZunUsdPool || isZunEthPool) {
                    result.push({
                        url: `https://curve.fi/#/arbitrum/pools/${pool.id}/deposit`,
                        address: pool.address,
                        id: pool.id,
                        icons: [
                            `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/${pool.coins[0].address.toLowerCase()}.png`,
                            `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets-arbitrum/${pool.coins[1].address.toLowerCase()}.png`
                        ],
                        coins: `${pool.coins[0].symbol}/${pool.coins[1].symbol}`,
                        protocol: 'curve',
                        chain: 'arbitrum',
                        mode,
                        tvlUsd: pool.usdTotal,
                        apy: pool.gaugeCrvApy[0],
                        projApy: pool.gaugeCrvApy[1],
                        reserves: pool.coins,
                    });
                }
            });

            let etcPrice = await fetch('https://ticker-api.cointelegraph.com/rates/?full=true');
            etcPrice = await etcPrice.json();
            // @ts-ignore
            etcPrice = etcPrice.data.ETH.USD.price;

            const coinPrices = {
                '0x4621b7a9c75199271f773ebd9a499dbd165c3191': 0, // DOLA
                '0x940181a94a35a4569e4529a3cdfb74e38fd98631': 0, // AERO
                '0xd5b9ddb04f20ea773c9b56607250149b26049b1f': 0, // zunUSD
                '0x24cb2b89844604c57350776d81e14765d03b91de': 0, // zunETH
                '0x1db0fc8933f545648b54a9ee4326209a9a259643': 0, // ZUN
            };

            const client = createPublicClient({
                chain: getChainById(chainId),
                transport: http(alchemyBaseTransportUrl),
            });

            let prices: any = await client.readContract({
                address: '0x3B06c787711ecb5624cE65AC8F26cde10831eb0C',
                abi: aeroSugarAbi,
                functionName: "getManyRatesToEthWithCustomConnectors",
                args: [
                    Object.keys(coinPrices),
                    false,
                    Object.keys(coinPrices),
                    10,
                ],
            });

            prices.map((item: BigInt, index: number) => {
                const val = Number(etcPrice) * 
                    Number(new BigNumber(item.toString()).dividedBy(BIG_TEN.pow(18)).toPrecision(5));

                // @ts-ignore
                coinPrices[Object.keys(coinPrices)[index]] = val;

                return val;
            });

            // @ts-ignore
            coinPrices['0x24cb2b89844604c57350776d81e14765d03b91de'] = etcPrice;

            const aeroPools = ['sAMM-DOLA/zunUSD', 'vAMM-ZUN/zunETH'];
            let arbPools: any = await client.readContract({
                address: '0x51f290CCCD6a54Af00b38edDd59212dE068B8A4b',
                abi: arbAllAbi,
                functionName: 'all',
                args: [100, 1800],
            });

            arbPools = arbPools.concat(
                await client.readContract({
                    address: '0x51f290CCCD6a54Af00b38edDd59212dE068B8A4b',
                    abi: arbAllAbi,
                    functionName: 'all',
                    args: [100, 2000],
                })
            );

            arbPools = arbPools.filter((item: any) => aeroPools.indexOf(item.symbol) !== -1);

            arbPools.forEach((arbPool: any) => {
                const reserve0 = bigNumberToNumber(new BigNumber(arbPool.reserve0));
                const reserve1 = bigNumberToNumber(new BigNumber(arbPool.reserve1));
                // @ts-ignore
                const price0 = coinPrices[arbPool.token0.toLowerCase()];
                // @ts-ignore
                const price1 = coinPrices[arbPool.token1.toLowerCase()];

                let tvlUsd = (reserve0 * price0);
                tvlUsd += (reserve1 * price1);

                let apy = 0;
                const staked0 = bigNumberToNumber(arbPool.staked0);
                const staked1 = bigNumberToNumber(arbPool.staked1);
                const emissions = bigNumberToNumber(arbPool.emissions);

                // @ts-ignore
                apy = 100 * 60 * 60 * 24 * 365 * emissions * coinPrices['0x940181a94a35a4569e4529a3cdfb74e38fd98631'];
                apy = apy / ((staked0 * price0) + (staked1 * price1));

                let symbol0 = arbPool.symbol.split('/')[0];
                symbol0 = symbol0.replace('vAMM-', '');
                symbol0 = symbol0.replace('sAMM-', '');
                let symbol1 = arbPool.symbol.split('/')[1];

                result.push({
                    url: `https://aerodrome.finance/deposit?token0=${arbPool.token0}&token1=${arbPool.token1}&type=${arbPool.symbol === 'sAMM-DOLA/zunUSD' ? '0' : '-1'}`,
                    id: arbPool.symbol,
                    icons: [
                        `https://raw.githubusercontent.com/SmolDapp/tokenAssets/main/tokens/8453/${arbPool.token0.toLowerCase()}/logo.svg`,
                        `https://raw.githubusercontent.com/SmolDapp/tokenAssets/main/tokens/8453/${arbPool.token1.toLowerCase()}/logo.svg`,
                    ],
                    coins: arbPool.symbol,
                    protocol: 'aerodrome',
                    chain: 'base',
                    tvlUsd: tvlUsd,
                    apy: apy,
                    mode: arbPool.symbol === 'sAMM-DOLA/zunUSD' ? 'zunUSD' : 'zunETH',
                    projApy: 0,
                    reserves: [
                        {
                            poolBalance: arbPool.reserve0.toString(),
                            symbol: symbol0
                        },
                        {
                            poolBalance: arbPool.reserve1.toString(),
                            symbol: symbol1
                        }
                    ]
                });
            });

            // @ts-ignore
            setAllPools(result);
        }

        getPoolsData();
    }, [chainId]);

    const poolsFiltered = useMemo(() => {
        let result = allPools;

        switch (stakingMode) {
            case 'zunUSD':
                result = result.filter((item: any) => item.mode === 'zunUSD');
                break;
            case 'ZETH':
                result = result.filter((item: any) => item.mode === 'zunETH');
                break;
            case 'ZUNBTC':
                result = result.filter((item: any) => item.mode === 'zunBTC');
                break;
        }

        if (chainFilter !== '') {
            result = result.filter((item: any) => item.chain === chainFilter);
        }

        if (protoFilter !== '') {
            result = result.filter((item: any) => item.protocol === protoFilter);
        }

        return result;
    }, [allPools, chainFilter, protoFilter, stakingMode]);

    const collateralAprNow = useMemo(() => {
        let result = '';

        if (uzdStatLoading) {
            result = 'loading';
        }

        switch (stakingMode) {
            case 'zunUSD':
                result = `${Number(uzdStatData.info.zunUSD.apr).toLocaleString('en', {
                    maximumFractionDigits: 0,
                })}%`;
                break;
            case 'ZETH':
                result = `${Number(uzdStatData.info.zunETH.apr).toLocaleString('en', {
                    maximumFractionDigits: 0,
                })}%`;
                break;
            case 'ZUNBTC':
                result = `${Number(uzdStatData.info.zunBTC.apr).toLocaleString('en', {
                    maximumFractionDigits: 6,
                })}%`;
                break;
        }

        return result;
    }, [uzdStatLoading, stakingMode, uzdStatData]);

    const totalCirculating = useMemo(() => {
        let result = '';

        switch (stakingMode) {
            case 'zunUSD':
                result = getFullDisplayBalance(totalSupply, 18, 2);
                break;
            case 'ZETH':
                result = getFullDisplayBalance(totalSupply, 18, 5);
                break;
            case 'ZUNBTC':
                result = getFullDisplayBalance(totalSupply, 18, 9);
                break;
        }

        return Number(result).toLocaleString('en', { maximumFractionDigits: 6 });
    }, [stakingMode, totalSupply]);

    const totalApy = useMemo(() => {
        let result = 0;

        switch (stakingMode) {
            case 'zunUSD':
                result = uzdStatData.info.zunUSDAps.apy + uzdStatData.info.zunUSDApsStaking.apr;
                break;
            case 'ZETH':
                result = uzdStatData.info.zunETHAps.apy + uzdStatData.info.zunETHApsStaking.apr;
                break;
            case 'ZUNBTC':
                result = uzdStatData.info.zunBTCAps.apy + uzdStatData.info.zunBTCApsStaking.apr;
                break;
        }

        return Number(result).toLocaleString('en', { maximumFractionDigits: 2 });
    }, [stakingMode, uzdStatData]);

    return (
        <React.Fragment>
            <MobileSidebar />
            <AllServicesPanel />
            <div className="container">
                <div className="row main-row h-100 ZunStablesContainer">
                    <SideBar isMainPage={false} tvl={tvl}>
                        <SidebarTopButtons />
                        <div className="mobile-menu-title d-block d-xxl-none">Menu</div>
                        <div
                            className="d-flex d-lg-none gap-3 mt-4 pb-3 mobile-menu"
                            style={{
                                fontSize: '13px',
                                overflowX: 'scroll',
                            }}
                        >
                            {renderMobileMenu()}
                        </div>
                        {/* <div className="card mt-3 zun-token-card">
                            <div className="card-body p-3">
                                <div className="ms-2 mt-2">
                                    <span>Your data</span>
                                </div>
                                <div className="balance">
                                    <div className="d-flex flex-row small-block align-items-center stablecoin mb-3 ps-3 me-3 me-lg-2 mt-3 justify-content-between">
                                        <div>
                                            <div>
                                                <span className="name">Balance</span>
                                            </div>
                                            <div className="vela-sans value mt-1 d-flex align-items-center">
                                                <span>${getFullDisplayBalance(zunUsdBalance)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <ZunPoolSummary
                            logo="USD"
                            selected={stakingMode === 'zunUSD'}
                            baseApy={uzdStatData.info.zunUSD.apr.toFixed(2)}
                            tvl={`$${Math.round(
                                Number(getFullDisplayBalance(uzdStatData.info.zunUSD.tvl))
                            ).toLocaleString('en', {
                                maximumFractionDigits: 0,
                            })}`}
                            className="mt-3"
                            onSelect={() => {
                                setStakingMode('zunUSD');
                            }}
                        />
                        <ZunPoolSummary
                            logo="ETH"
                            selected={stakingMode === 'ZETH'}
                            baseApy={uzdStatData.info.zunETH.apr.toFixed(2)}
                            tvl={`$${Math.round(
                                Number(uzdStatData.info.zunETH.tvlUsd)
                            ).toLocaleString('en', {
                                maximumFractionDigits: 0,
                            })}`}
                            className="mt-3"
                            onSelect={() => {
                                setStakingMode('ZETH');
                            }}
                        />
                        <ZunPoolSummary
                            logo="BTC"
                            selected={stakingMode === 'ZUNBTC'}
                            baseApy={uzdStatData.info.zunBTC.apr.toFixed(2)}
                            tvl={`$${Math.round(
                                Number(uzdStatData.info.zunBTC.tvlUsd)
                            ).toLocaleString('en', {
                                maximumFractionDigits: 0,
                            })}`}
                            className="mt-3"
                            onSelect={() => {
                                setStakingMode('ZUNBTC');
                            }}
                        />
                    </SideBar>
                    <div className="col content-col dashboard-col">
                        <Header section="uzd" />
                        {/* First row */}
                        <div className="row ms-md-4">
                            {/* Infobar col */}
                            <div className="col-xxl-7 col-xs-12">
                                <div className={`card m-xxl-3 mt-xxl-0 h-100`}>
                                    <div className="card-body p-3">
                                        <div className="title">Swap</div>
                                        <CoinSwapPanel
                                            className="mt-3"
                                            stakingMode={stakingMode}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Buy zunUSD on */}
                            <div className="col-xxl-5 col-xs-12 d-flex flex-column mt-3 mt-xxl-0">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="title">Info bar</div>
                                        <div className="row mt-3">
                                            <div className="col-6 col-md-6 col-xxl-6">
                                                <MicroCard
                                                    title="Total circulating"
                                                    hint="The amount of coins that have already been created and circulating in the market."
                                                    value={totalCirculating}
                                                    className="align-items-start stablecoin mb-3 ps-3 me-3 me-lg-2"
                                                />
                                            </div>
                                            <div className="col-6 col-md-6 col-xxl-6">
                                                <MicroCard
                                                    title="Collateral"
                                                    hint="Value of the Omnipool reserves."
                                                    value={
                                                        stakingMode === 'ZUNBTC' ? totalCirculating : collateralValue
                                                    }
                                                    className="align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2"
                                                />
                                            </div>
                                            <div className="col-6 col-md-6 col-xxl-6">
                                                <MicroCard
                                                    title="Contract address"
                                                    className="align-items-start stablecoin mb-3 ps-3 me-3 me-lg-2"
                                                >
                                                    <AddressButtons
                                                        title={stakingMode}
                                                        icon={`${zunCoinName}.svg`}
                                                        address={getSelectedCoinAddress(
                                                            chainId,
                                                            stakingMode
                                                        )}
                                                        link={true}
                                                    />
                                                </MicroCard>
                                            </div>
                                            <div className="col-6 col-md-6 col-xxl-6">
                                                <MicroCard
                                                    title={`${zunCoinName} price`}
                                                    hint="Current market price on Curve Finance."
                                                    value={
                                                        // @ts-ignore
                                                        !curveDataLoading ? zunCoinPool[stakingMode] : '0'
                                                    }
                                                    className="align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2"
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 col-xxl-12">
                                                <div
                                                    id="stake-and-boost"
                                                    className="gray-block small-block align-items-start stablecoin ps-3 me-0 me-lg-2"
                                                >
                                                    <svg
                                                        width="69"
                                                        height="66"
                                                        viewBox="0 0 69 66"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="bg"
                                                    >
                                                        <path
                                                            d="M28.5498 42.9343C23.9987 41.3666 19.8065 39.9224 15.6142 38.4784C12.0415 37.2478 8.46894 36.0167 4.89657 34.7851C2.30199 33.8859 1.75666 31.3292 3.77037 29.4594C23.3708 11.2599 42.9733 -6.93727 62.5782 -25.132C62.9632 -25.4893 63.3312 -25.867 63.7348 -26.2018C64.946 -27.2066 66.4524 -27.1963 67.7088 -26.1953C68.2735 -25.7565 68.6604 -25.1277 68.7977 -24.4254C68.935 -23.7231 68.8135 -22.9951 68.4555 -22.3762C66.8052 -19.3814 65.1112 -16.4107 63.4321 -13.4319C57.1514 -2.28968 50.8687 8.85143 44.5842 19.9914C44.3969 20.2795 44.1947 20.5576 43.9784 20.8244C44.4916 21.0188 44.8106 21.1494 45.1359 21.2614C51.9706 23.615 58.8064 25.9652 65.6434 28.3119C66.6901 28.67 67.5467 29.1941 67.9235 30.2998C68.3679 31.6039 67.9787 32.6704 67.0416 33.616C64.13 36.5539 61.2254 39.4988 58.3278 42.4507C40.7844 60.2369 23.2403 78.0224 5.69535 95.8071C5.42642 96.0944 5.13691 96.3617 4.8291 96.6068C4.32822 96.987 3.71748 97.1941 3.08868 97.1968C2.45988 97.1995 1.847 96.9977 1.34212 96.6218C0.281335 95.8331 -0.151054 94.5281 0.292686 93.2901C0.488085 92.802 0.723109 92.3308 0.99541 91.8813C9.90682 75.9186 18.8224 59.9583 27.7421 44.0003C27.9319 43.6607 28.214 43.3728 28.5498 42.9343Z"
                                                            fill="url(#paint0_linear_968_4725)"
                                                            fillOpacity="0.47"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_968_4725"
                                                                x1="5.56752"
                                                                y1="93.586"
                                                                x2="78.117"
                                                                y2="-56.9332"
                                                                gradientUnits="userSpaceOnUse"
                                                            >
                                                                <stop
                                                                    stopColor="white"
                                                                    stopOpacity="0"
                                                                />
                                                                <stop
                                                                    offset="1"
                                                                    stopColor="white"
                                                                    stopOpacity="0.63"
                                                                />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <div className="text-white d-flex align-items-center">
                                                        <div className="col-6">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span className="name2">
                                                                    Stake and boost up to {totalApy}% in APS!
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 d-flex align-items-center pe-3 ps-3 justify-content-end">
                                                            <Link to="/">
                                                                <button className="zun-button w-100">
                                                                    Stake
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Second row */}
                        <div className="row ms-md-4 mt-3 h-100 mb-3">
                            <div className="col">
                                <div className="card ms-xxl-3 mt-xxl-0 h-100">
                                    <div className="card-body p-4">
                                        <div className="title">Collateral Analytics</div>
                                        <div id="collateral-block" className="row mt-3">
                                            <div className="col-xs-12 col-md-4">
                                                <MicroCard
                                                    title="Collateral APR now"
                                                    value={collateralAprNow}
                                                    className="align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2"
                                                />
                                                <MicroCard
                                                    title="Average APR"
                                                    popover={apyPopover}
                                                    value="in 30, 90 days"
                                                    className="align-items-start stablecoin mb-3 ps-3 me-0 me-lg-2"
                                                />
                                            </div>
                                            <div className="col-xs-12 col-md-8">
                                                <ApyChart
                                                    legend={false}
                                                    data={{
                                                        labels: histApyData.map((item: any) => {
                                                            return format(item.timestamp * 1000, 'dd MMM');
                                                        }),
                                                        datasets: [
                                                            {
                                                                label: 'APY',
                                                                data: histApyData.map((item: any) => item.value),
                                                                borderColor: '#FE9401',
                                                            },
                                                        ],
                                                    }}
                                                    onRangeChange={(range: string) => {
                                                        setHistApyPeriod(range);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col col-xs-12 col-lg-5">
                                                <PieChart2 data={poolList} hideList hideSummary />
                                            </div>
                                            <div className="col col-xs-12 col-lg-7">
                                                <div className="row">
                                                    {poolList.map((pool: any) => (
                                                        <div
                                                            key={pool.address}
                                                            className="col-xs-12 col-sm-12 col-lg-6"
                                                        >
                                                            <StrategyListItem
                                                                address={pool.address}
                                                                title={pool.title}
                                                                description={pool.description}
                                                                percent={pool.value}
                                                                color={pool.color}
                                                                amount={pool.amount}
                                                                apr={pool.apr.toFixed(2)}
                                                                icon={pool.icon}
                                                                primaryIcon={pool.primaryIcon}
                                                                secondaryIcon={pool.secondaryIcon}
                                                                chainId={chainId}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ms-md-4 mt-2 h-100 mb-3">
                            <div className="col">
                                <div className="card ms-xxl-3 mt-xxl-0 h-100">
                                    <div className="card-body p-4">
                                        <div className="d-flex justify-content-between">
                                        <div className="title">Pools</div>
                                        <div className="d-flex gap-2">
                                            <select
                                                className="zun-dropdown"
                                                onChange={(e) => {
                                                    setChainFilter(e.currentTarget.value);
                                                }}
                                            >
                                                <option value="">Chain</option>
                                                <option value="ethereum">Mainnet</option>
                                                <option value="base">Base</option>
                                                <option value="arbitrum">Arbitrum</option>
                                            </select>
                                            <select
                                                className="zun-dropdown"
                                                onChange={(e) => {
                                                    setProtoFilter(e.currentTarget.value);
                                                }}
                                            >
                                                <option>Protocol</option>
                                                <option value="aerodrome">Aerodrome</option>
                                                <option value="curve">Curve</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="row mt-3">
                                        <table className="pools mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Pool name</th>
                                                    <th>Protocol</th>
                                                    <th>Chain</th>
                                                    <th className="text-center">TVL</th>
                                                    <th className="text-center">Pool balance</th>
                                                    <th>APR</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !allPools.length && (
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className="inner">
                                                                    <Preloader />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    // @ts-ignore
                                                    poolsFiltered.map((pool: any) => (
                                                        <tr key={pool.id} className="col-xs-12 col-sm-12 col-lg-6">
                                                            <td>
                                                                <div className="d-flex gap-2 inner align-items-center">
                                                                    <div className="wrapper me-1">
                                                                        {
                                                                            // @ts-ignore
                                                                            pool.icons.map((coin: string) => (
                                                                                <div className="coin" key={coin}>
                                                                                    <img src={coin} alt="" />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    <div>{pool.coins}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex inner align-items-center">
                                                                    <img src={`/${pool.protocol}.svg`} alt="" className="icon" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex inner align-items-center">
                                                                    <img src={`/${pool.chain}.svg`} alt="" className="icon" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex inner align-items-center justify-content-center">
                                                                    {`$${Number(pool.tvlUsd).toLocaleString('en', { maximumFractionDigits: 0 })}`}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex inner align-items-center flex-column">
                                                                    <div>
                                                                        {
                                                                            Number(getFullDisplayBalance(pool.reserves[0].poolBalance)).toLocaleString('en', { maximumFractionDigits: 0 })
                                                                        } {pool.reserves[0].symbol}
                                                                    </div>
                                                                    <div className="divider"></div>
                                                                    <div>
                                                                        {
                                                                            Number(getFullDisplayBalance(pool.reserves[1].poolBalance)).toLocaleString('en', { maximumFractionDigits: 0 })
                                                                        } {pool.reserves[1].symbol}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex inner align-items-center">
                                                                    {Number(pool.apy).toLocaleString('en', { maximumFractionDigits: 2 })}% 
                                                                    {pool.projApy ? `→ ${Number(pool.projApy).toLocaleString('en', { maximumFractionDigits: 2 })}%` : ''}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="inner">
                                                                    <a href={pool.url} className="zun-button open-link" target="_blank" rel="noreferrer">Open</a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};