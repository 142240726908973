import React, { useState, useCallback, useEffect } from 'react';
import './WalletButton.scss';
import { useNetwork, useDisconnect } from 'wagmi';
import useAccount from '../../hooks/useAccountOverride';
import { networks, Network } from '../NetworkSelector/NetworkSelector';
import { WalletsModal } from '../WalletsModal/WalletsModal';
import { getChainClient } from '../../utils/zunami';
import { Address, GetEnsNameReturnType } from 'viem';
import llamasAbi from '../../actions/abi/llama/llamas_factory.json';

interface WalletButtonProps {}

export const TheLlamas: Address = '0xe127ce638293fa123be79c25782a5652581db234';

export const WalletButton = (
    props: WalletButtonProps & React.HTMLProps<HTMLButtonElement>
): JSX.Element => {
    const { disconnect } = useDisconnect();
    const { address: account } = useAccount();
    const [activeNetwork, setActiveNetwork] = useState<Network>(networks[0]);
    const [chainSupported, setChainSupported] = useState(false);
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : 1;

    const [nftUrl, setNftUrl] = useState<string>('');

    useEffect(() => {
        async function getNFT() {
            if (!account || chainId !== 1) {
                setNftUrl('');
                return '';
            }

            const tokens = await getChainClient(chainId).readContract({
                address: TheLlamas,
                abi: llamasAbi,
                functionName: 'tokensForOwner',
                args: [account],
                // args: ['0x62c8aCc91b488F5749D8e43C7711eDf76841b206'],
            });

            // @ts-ignore
            if (tokens.length === 0) {
                setNftUrl('');
                return;
            }

            const tokenUri = await getChainClient(chainId).readContract({
                address: TheLlamas,
                abi: llamasAbi,
                functionName: 'tokenURI',
                // @ts-ignore
                args: [tokens[0]],
            });

            // @ts-ignore
            const tokenResp = await fetch(tokenUri);
            const token = (await tokenResp.json()) as { image: string };

            // @ts-ignore
            setNftUrl(token.image);
        }

        getNFT();
    }, [account, chainId]);

    const [ensName, setEnsName] = useState<GetEnsNameReturnType>('');

    useEffect(() => {
        async function getName() {
            if (!account || chainId !== 1) {
                return '';
            }

            try {
                setEnsName(
                    await getChainClient().getEnsName({
                        address: account,
                    })
                );
            } catch (e) {
                
            }
        }

        getName();
    }, [account, chainId]);

    useEffect(() => {
        if (!chainId) {
            return;
        }

        const defaultNetwork = networks.filter(
            (network) => parseInt(network.key, 16) === chainId
        )[0];

        setActiveNetwork(defaultNetwork);
    }, [chainId, activeNetwork]);

    const handleSignOutClick = useCallback(() => {
        window.localStorage.clear();
        disconnect();
    }, []);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!chainId) {
            return;
        }

        const supportedChainIds = [1, 56, 137];

        const defaultNetwork = networks.filter(
            (network) => parseInt(network.key, 16) === chainId
        )[0];

        // if (!hideActiveNetwork) {
        setActiveNetwork(defaultNetwork);
        // }

        if (!activeNetwork) {
            setChainSupported(false);
            return;
        }

        setChainSupported(supportedChainIds.indexOf(parseInt(activeNetwork.key, 16)) !== -1);
    }, [chainId, activeNetwork]);

    const shortAddress = account
        ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
        : '';

    return (
        <React.Fragment>
            <button
                id="connect-wallet-btn"
                className={`WalletButton WalletButton-${
                    account ? 'connected' : 'disconnected'
                } ${props.className ? props.className : ''} btn btn-link`}
                onClick={() => {
                    if (account) {
                        handleSignOutClick();
                    } else {
                        setShow(true);
                    }
                }}
            >
                {!nftUrl && account && (
                    <img src="/wallet-connected.png" alt="" style={{ height: '13px' }} />
                )}

                {!account && (
                    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="13" rx="3" fill="#979797"/>
                            <rect y="3" width="18" height="10" rx="3" fill="url(#paint0_linear_67_1074)"/>
                        <defs>
                        <linearGradient id="paint0_linear_67_1074" x1="9" y1="3" x2="9" y2="13" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E3E3E3"/>
                            <stop offset="1" stopColor="#6B6B6B"/>
                        </linearGradient>
                        </defs>
                    </svg>
                )}
                {!account && <span className="add-wallet-label ms-2">Add wallet</span>}

                {account && nftUrl && <img src={nftUrl} alt="TheLLAMAS" className="nft" />}

                {account && (
                    <span id="address" className="address">
                        {ensName ? ensName : shortAddress}
                    </span>
                )}
            </button>
            <WalletsModal
                show={show}
                onHide={() => {
                    setShow(false);
                }}
                onWalletConnected={() => {
                    setShow(false);
                }}
            />
        </React.Fragment>
    );
};
